/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-25 21:58:17
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-04-30 15:08:53
 */
import { ComputedRef, computed } from "vue";
import { TaggingItem } from "../interface/common";
import {
  ProductItem,
  ProductItemImage,
  ProductItemOfficialPrices,
} from "../interface/order";
import { NumberOrString } from "../type/common";
import { CurrencyType } from "../type/order";

export const getOrderItemAmountTotal = computed(() => {
  return (
    confirmedQty: number,
    unitPrice: number,
    discountPrice: number,
    discountType: number,
    taxCount: number,
    isTaxCount = true
  ) => {
    const qty = confirmedQty || 0,
      price = Number(unitPrice),
      prices = price * qty,
      discount = Number(discountPrice),
      discount_type = discountType,
      tax = taxCount;
    if (isTaxCount) {
      return getOrderItemTaxTotal(price, qty, discount, discount_type, tax);
    } else {
      return getOrderItemTotal(price, qty, discount, discount_type, tax);
    }
  };
});

const getOrderItemTotal = (
  price: number,
  qty: number,
  discount: number,
  discount_type: number,
  tax: number
) => {
  if (discount_type === CurrencyType.Amount) {
    const amounts = (price - discount) * qty;
    return amounts > 0 ? amounts : 0;
  } else {
    const amounts = price * ((100 - discount) / 100) * qty;
    return amounts > 0 ? amounts : 0;
  }
};

const getOrderItemTaxTotal = (
  price: number,
  qty: number,
  discount: number,
  discount_type: number,
  tax: number
) => {
  if (discount_type === CurrencyType.Amount) {
    const amounts = ((price - discount) / (tax / 100 + 1)) * qty;
    return amounts > 0 ? amounts : 0;
  } else {
    const amounts =
      ((price * ((100 - discount) / 100)) / (tax / 100 + 1)) * qty;
    return amounts > 0 ? amounts : 0;
  }
};

export const getOrderItemVatTotal = computed(() => {
  return (
    confirmedQty: number,
    unitPrice: number,
    discountPrice: number,
    discountType: number,
    taxCount: number,
    isTaxCount = true
  ) => {
    const qty = confirmedQty || 0,
      price = Number(unitPrice),
      prices = price * qty,
      discount = Number(discountPrice),
      discount_type = discountType,
      tax = taxCount;
    if (isTaxCount) {
      return (
        getOrderItemTaxTotal(price, qty, discount, discount_type, tax) *
        (tax / 100)
      );
    } else {
      return (
        getOrderItemTotal(price, qty, discount, discount_type, tax) *
        (tax / 100)
      );
    }
  };
});

export const getCurrencyTypeOptions = (): TaggingItem[] => {
  return [
    {
      label: "%",
      value: CurrencyType.Percent,
    },
    {
      label: "€",
      value: CurrencyType.Amount,
    },
  ];
};

/**
 * @description: 获取对应的产品信息
 * @return ProductItem | null
 */
export const getOrderProductIdData = (
  id: NumberOrString,
  Products: ProductItem[]
): ProductItem | null => {
  const arr: ProductItem[] = Products.filter((item: ProductItem) => {
    return item.id === id;
  });
  if (arr.length == 0) {
    return null;
  } else {
    return arr[0];
  }
};

/**
 * @description: 获取产品信息为DE的价格信息
 * @return ProductItemOfficialPrices[]
 */
export const getOrderOfficialPrices = (
  officialPrices: ProductItemOfficialPrices[] | undefined
): ProductItemOfficialPrices[] => {
  let arr: ProductItemOfficialPrices[] = [];
  if (officialPrices) {
    arr = officialPrices.filter((item: ProductItemOfficialPrices) => {
      return item.country_iso_2 === "DE";
    });
  }
  return arr;
};

/**
 * @description: 获取预览图片
 * @return string[]
 */
export const previewOrderImages = (
  previewImages: ProductItemImage[] | undefined
): string[] => {
  // eslint-disable-next-line prefer-const
  let arr: string[] = [];
  if (previewImages) {
    previewImages.forEach((item: ProductItemImage) => {
      arr.push(item.origin_url);
    });
  }
  return arr;
};

// export const useComputedFn = <T>(
//   fn: (...args: unknown[]) => T
// ): ((...args: unknown[]) => ComputedRef<T>) => {
//   const cache: Map<string, ComputedRef<T>> = new Map();
//   return (...args: unknown[]) => {
//     const cacheKey = JSON.stringify(args);
//     if (cache.has(cacheKey)) return cache.get(cacheKey);
//     // const result = cache.set(cacheKey, args);
//     return;
//   };
// };

export const useComputedFn = <T, U extends unknown[]>(
  fn: (...args: U) => T
): ((...args: U) => ComputedRef<T>) => {
  const cache: Map<string, ComputedRef<T>> = new Map();
  return (...args: U): ComputedRef<T> => {
    const cacheKey = JSON.stringify(args);
    if (cache.has(cacheKey)) return cache.get(cacheKey) as ComputedRef<T>;
    const result = computed(() => fn(...args));
    cache.set(cacheKey, result);
    return result;
  };
};
